import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { toast, ToastContainer } from 'react-toastify';
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import {
  Box, Button, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { api } from '../../services/api';
import style from './documentosintegradora.module.scss';
import { DialogComponent } from '../../components/DialogComponent';

interface IntegratorDetalheData {
  id: number;
  name: String;
  cnpj: String;
  phoneNumber: String;
  potencyW: String;
  numWorkers: number;
  dataFundacao: String;
  status: String;
  user: {
    email: String;
  };
  address: {
    street1: String;
    number: number;
    street2: String;
    zip: String;
    city: {
      name: String;
      state: {
        name: String;
      };
    };
  };
}
export function DocumentosIntegradora() {
  const ref = useRef<LoadingBarRef>(null);

  const { 'nextauth.tag': tag } = parseCookies();
  const getUserId = () => parseInt(tag, 10);
  const [contrato, setContrato] = useState<any>();
  const [crea, setCrea] = useState<any>();
  const [balanco, setBalanco] = useState<any>();
  const [crp, setCrp] = useState<any>();
  const [cat, setCat] = useState<any>();
  const [integrator, setIntegrator] = useState<IntegratorDetalheData | null>(
    null,
  );

  const instrucoes = 'Envie os documentos abaixo, necessários para a fase final de avaliação do seu cadastro. Após enviar, em breve, você receberá uma nova mensagem informando do aceite ou não de seu cadastro.';

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const getIntegrator = async () => {
      setLoadingText('Verificando status!...');
      setIsOpen(true);
      const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/user/get', {
        id: getUserId(),
      });

      resp.then((resolved) => {
        const item: IntegratorDetalheData = resolved.data;
        setIntegrator(item);
        setIsOpen(false);
        return item;
      });
    };
    getIntegrator();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refreshPage = (interval: number) => setInterval(() => window.location.reload(), interval);
  const [errorMessageInput, setErrorMessageInput] = useState<string[]>([]);
  const [errorMessageUpload, setErrorMessageUpload] = useState<string[]>([]);

  const TestFileType = (fileName: string, fileTypes : [string]) => {
    if (!fileName) return;

    const dots = fileName.split('.');
    // get the part AFTER the LAST period.
    const fileType = `.${dots[dots.length - 1]}`;

    // eslint-disable-next-line consistent-return
    if (fileTypes.join('.').indexOf(fileType) !== -1) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };
  const handleCatChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCat(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleCatClear = () => {
    setCat(null);
  };

  const handleBalancoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setBalanco(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleBalancoClear = () => {
    setBalanco(null);
  };

  const handleCreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCrea(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleCreaClear = () => {
    setCrea(null);
  };

  const handleCrpChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCrp(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleCrpClear = () => {
    setCrp(null);
  };

  const handleContratoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setContrato(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleContratoClear = () => {
    setContrato(null);
  };

  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const response = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const validateContrato = (m: any) => m;
  const validateCrea = (m: any) => m;
  const validateBalanco = (m: any) => m;
  const validateCrp = (m: any) => m;
  const validateCat = (m: any) => m;
  const validateForm = () => {
    const messagesInput: any[] = [];
    const messagesUpload: any[] = [];

    if (!validateContrato(contrato)) {
      messagesUpload.push('Revise o upload do contrato social');
    }
    if (!validateCrea(crea)) {
      messagesUpload.push('Revise o upload do CREA');
    }
    if (!validateBalanco(balanco)) {
      messagesUpload.push('Revise o upload do balanço ou balancete');
    }
    if (!validateCrp(cat)) {
      messagesUpload.push('Revise o upload do Certidão de Acervo Técnico da empresa');
    }
    if (!validateCat(crp)) {
      messagesUpload.push('Revise o upload da certidão de registro profissional');
    }
    setErrorMessageInput(messagesInput);
    setErrorMessageUpload(messagesUpload);
    return messagesUpload.length === 0;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoadingText('Enviando cadastro...');
      setIsOpen(true);
      const urlv = window.location.href.split('/');
      const urlc = `${urlv[0]}//${urlv[2]}`;

      toast.info('Enviando Cadastro. Por favor, aguarde um instante.');
      const fcontrato = await sendFile('contrato', contrato);
      const fcat = await sendFile('cat', cat);
      const fcrea = await sendFile('crea', crea);
      const fcrp = await sendFile('crp', crp);
      const fbalanco = await sendFile('balanco', balanco);
      const integratorEmail = integrator?.user.email;
      const integratorId = integrator?.id;

      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          integratorEmail,
          integratorId,
          returnUrl: urlc,
          fcontrato,
          fcrea,
          fcat,
          fcrp,
          fbalanco,
        },
      };
      const response = await api.post('integrator/register/put', request);

      switch (response.data) {
        case 'DOCS_SEND_OK':
          setIsOpen(false);
          toast.success('Documentos enviados.');
          refreshPage(100);
          break;
        case 'UNABLE_TO_FIND_INTEGRATOR':
          setIsOpen(false);
          toast.error('Email já cadastrado na base de dados.');
          break;
        case 'UNABLE_TO_UPDATE_INTEGRATOR':
          setIsOpen(false);
          toast.error('Aconteceu um erro inesperado. Tente novamente.');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
      }
    } else {
      setIsOpen(false);
      toast.warn('Há um problema com os arquivos.');
    }
  };

  return (
    <>
      <LoadingBar color="#f11946" ref={ref} />
      <Box sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 2px 2px 0px #0000001A',
        padding: '1rem',
        borderRadius: '0.5rem',
        minHeight: '80vh',
      }}
      >
        {integrator?.status === 'Sem documentos' && (
        <form onSubmit={handleSubmit} id="formIntegrator" encType="multipart/form-data" className={style.form}>
          <div>
            <h4 className={style.title}>Documentos da Integradora</h4>
            <hr className={style.divisor} />
            <Typography sx={{ marginBottom: '1rem', fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>Envie os documentos abaixo, necessários para a fase final de avaliação do seu cadastro. Após enviar, em breve, você receberá uma nova mensagem informando do aceite ou não do seu cadastro.</Typography>
            <h4 className={style.title}>Documentação</h4>
            <hr className={style.divisor} />
            <Grid container spacing={2} rowSpacing={2} sx={{ margin: '1rem 0 1rem 0' }}>
              <Grid xs={12} md={6}>
                <TextField
                  label="Contrato social"
                  required
                  fullWidth
                  value={contrato ? contrato.name : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label">
                          {contrato && (
                          <IconButton onClick={handleContratoClear}>
                            <ClearIcon />
                          </IconButton>
                          )}
                        </IconButton>
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            hidden
                            onChange={handleContratoChange}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !!contrato,
                  }}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Certidão do CREA"
                  required
                  fullWidth
                  value={crea ? crea.name : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label">
                          {crea && (
                          <IconButton onClick={handleCreaClear}>
                            <ClearIcon />
                          </IconButton>
                          )}
                        </IconButton>
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            hidden
                            onChange={handleCreaChange}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !!crea,
                  }}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Balanço"
                  fullWidth
                  required
                  value={balanco ? balanco.name : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label">
                          {balanco && (
                          <IconButton onClick={handleBalancoClear}>
                            <ClearIcon />
                          </IconButton>
                          )}
                        </IconButton>
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            hidden
                            onChange={handleBalancoChange}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !!balanco,
                  }}
                  helperText={(
                    <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>
                      Aceitamos também o balancete.
                      Deve ser dos últimos 12 meses.
                    </Typography>
                      )}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label="Certidão técnica"
                  required
                  fullWidth
                  value={cat ? cat.name : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label">
                          {cat && (
                          <IconButton onClick={handleCatClear}>
                            <ClearIcon />
                          </IconButton>
                          )}
                        </IconButton>
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            hidden
                            onChange={handleCatChange}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !!cat,
                  }}
                  helperText={(
                    <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>
                      Aceitamos também o acervo técnico da empresa.
                    </Typography>
                      )}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid xs={12} md={12}>
                <TextField
                  label="Certidão profissional"
                  required
                  fullWidth
                  value={crp ? crp.name : ''}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton component="label">
                          {crp && (
                          <IconButton onClick={handleCrpClear}>
                            <ClearIcon />
                          </IconButton>
                          )}
                        </IconButton>
                        <IconButton component="label">
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            hidden
                            onChange={handleCrpChange}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: !!crp,
                  }}
                  helperText={(
                    <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>
                      Certidão de registro profissional.
                    </Typography>
                      )}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <hr className={style.divisor} />
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '1rem',
            }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '14rem',
                  borderRadius: '2rem',
                  borderColor: '#EB5A12',
                  color: '#EB5A12',
                  marginBottom: '0.5rem',
                  marginTop: '1rem',
                  '&:hover': {
                    borderColor: '#EB5A12',
                    backgroundColor: 'rgba(255,127,80,0.2)',
                  },
                }}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: '14rem',
                  borderRadius: '2rem',
                  backgroundColor: '#EB5A12',
                  marginBottom: '0.5rem',
                  marginTop: '1rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#FF7F50',
                  },
                }}
              >
                Enviar
              </Button>
            </Box>
          </div>
        </form>
        )}
        {!(integrator?.status === 'Sem documentos') && (
          <>
            <h4 className={style.title}>Documentos da Integradora</h4>
            <hr className={style.divisor} />
            <Typography sx={{ marginBottom: '1rem', fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>Documentos em análise!</Typography>
          </>
        )}
      </Box>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
