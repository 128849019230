import React, {
  ChangeEvent, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { LoremIpsum, Avatar, loremIpsum } from 'react-lorem-ipsum';
import { render } from 'react-dom';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { toast, ToastContainer } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import Pusher from 'pusher-js';
import { pdfjs } from 'react-pdf';
import style from './analisecredito.module.scss';
import { FormPage } from '../../components/FormPage';
import { StepperProject } from '../../components/StepperProject';
import { api } from '../../services/api';
import uploadIcon from '../../assets/images/upload.png';
import { Post } from '../../components/Posts';
import { TestFileType, timeStampToDate } from '../../utils/util';
import { DialogComponent } from '../../components/DialogComponent';
import pdfIcon from '../../assets/images/downloadPDF.png';
import { getFile } from '../../lib/download';
import { sendFile } from '../../lib/upload';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { usePusher } from '../../hooks/PusherContext';

interface CreditOption{
  id: number;
  parcelas: number;
  banco: string;
  valorParcela: number;
  taxa: number
  choosed: boolean
  bancoDoc: any
}

interface GetState {
  id: number;
}

interface ProjectDetail{
  id:number
  nome: string;
  chosen_analysisId: number
}
interface Message {
  userID: number,
  senderName: string,
  receiverName: string,
  message: string,
  isFile: boolean,
  fileTitle: string,
  filePath: string,
  created_at: string,
  direction: string,
  messageFile: any
}

interface CostumerDetail {
  nome: string,
  cpf: string,
  rg: string,
  dataNascimento: Date,
  nomeMae: string,
  telefone: string,
  faturamentoMensal: number,
  email: string,
  mediaContaLuz: number,
  potenciaInstalada: number,
  contaLuzRecente: string,
  documentoIdentificacao: string,
  iptu: string,
  comprovanteRenda: string,
  notaFiscalEquipamentos: string,
  enderecoInstalacao: {
    city : {
      name : string,
      state : {
        name: string
      }
    },
    number: number,
    street1: string,
    street2: string,
    zip: string
  },
  status: string
}

export function AnaliseCredito() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const canvasRef = useRef(null);
  const history = useHistory();
  const ref = useRef<LoadingBarRef>(null);
  const [creditOptions, setCreditOptions] = useState<CreditOption[]>([]);
  const [costumer, setCostumer] = useState<CostumerDetail | null>(null);
  const [user, setUser] = useState('');
  const [project, setProject] = useState<ProjectDetail | null>(null);
  const [projectStatus, setProjectStatus] = useState<string>('');
  const [message, setMessage] = useState('');
  const [documentUrl, setDocumentUrl] = useState<string>('');

  const [numPages, setNumPages] = useState<number>(0);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [enableButtonClick, setEnableButtonClick] = useState(false);
  const [enableRadioClick, setRadioButtonClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [isCostumerOwner, setIsCostumerOwner] = useState<Boolean>(true);
  const [radioTypeFamilyValue, setRadioTypeFamilyValue] = useState(0);
  const [proposal, setProposal] = useState<any>();
  const [contaLuz, setContaLuz] = useState<any>();
  const [identidade, setIdentidade] = useState<any>();
  const [iptu, setIptu] = useState<any>();
  const [comprovanteRenda, setComprovanteRenda] = useState<any>();

  const [loadedFilesCostumer, setLoadedFilesCostumer] = useState({
    contaLuz: null,
    identidade: null,
    iptu: null,
    comprovanteRenda: null,
  });
  const [loadedFiles, setLoadedFiles] = useState({
    proposal: null,
  });

  const [messageFile, setMessageFile] = useState<any>(null);

  const [chosen, setChosen] = useState(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const sstate: GetState = history.location.state as GetState;

  const pusher = usePusher();

  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return parseInt(userId, 10);
  };
  const getUserRole = () => {
    const { 'nextauth.roles': role } = parseCookies();
    return role;
  };
  const userID = getUserId();
  const role = getUserRole();
  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };
  const none = (index : number, chosed: boolean) => {
    for (let i = 0; i < creditOptions.length; i++) {
      if (i === index) {
        creditOptions[index].choosed = true;
      } else {
        creditOptions[index].choosed = false;
      }
    }
  };

  const disableFields = () => {
    setAttribute('upload_contaluz', 'disabled', 'disabled');
    setAttribute('upload_identidade', 'disabled', 'disabled');
    setAttribute('upload_comprovanteRenda', 'disabled', 'disabled');
    setAttribute('upload_iptu', 'disabled', 'disabled');
  };

  useEffect(() => {
    const getProjectData = async () => {
      setLoadingText('Carregando dados...');
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'project/single/get',
        { projectId: getProjectId() },
      );

      response.then((resolved) => {
        const { data } = resolved;
        if (data !== 'COULD_NOT_LOCATE_PROJECT') {
          // whether filled already
          setProjectStatus(data.status);
          if (data.nome.length !== 0) {
            setChosen(data.chosen_analysisId);
            // enableButtonClick;
          }
          if (data.status === 'Inativo') {
            disableFields();
          }

          setLoadedFiles({
            proposal: data.proposal || 'Sem documentos',
          });
        }
      });
    };
    const getToday = () => {
      const date = new Date();
      const day = date.getDate().toString();
      let month = ((date.getMonth() + 1).toString());
      if (month.length === 1) month = `0${month}`;
      const hour = date.getHours();
      const min = date.getMinutes();
      const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
      const year = date.getFullYear().toString();

      // check if date is after today
      return `${day}/${month}/${year} - ${hour}:${min}`;
    };
    const getCostumer = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('project/costumer/get', {

        projectId: getProjectId(),
      });

      resp.then((resolved) => {
        const item: CostumerDetail = resolved.data;
        setCostumer(item);

        const costumerDocs = resolved.data;
        if (costumerDocs !== 'COULD_NOT_FIND_COSTUMER') {
          setLoadedFilesCostumer({
            contaLuz: costumerDocs.contaLuzRecente,
            identidade: costumerDocs.documentoIdentificacao,
            iptu: costumerDocs.iptu,
            comprovanteRenda: costumerDocs.comprovanteRenda,
          });
        }
        setIsOpen(false);
      });
    };

    const getUser = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('/user/get', {
        userId: getUserId(),
      });

      resp.then((resolved) => {
        setUser(resolved.data.name);
      });
    };
    const getEnables = async () => {
      if (projectStatus === 'Inativo') {
        setEnableButtonClick(true);
        return;
      }
      if (chosen === 0) {
        setEnableButtonClick(false);
        setRadioButtonClick(false);
      } else {
        setEnableButtonClick(false);
        setRadioButtonClick(true);
      }
    };
    const getCreditOptions = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('project/credit/get', {
        projectId: getProjectId(),
      });

      resp.then((resolved) => {
        setCreditOptions(resolved.data);
      });
    };

    let userName = '';
    const getMessages = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post(
        '/message/get',
        { projectId: getProjectId() },
      );

      response.then(async (resolved) => {
        const { data } = resolved;
        const newMessages: Message[] = [];
        for (let i = 0; i < data.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const resp = await api.post('/user/get', {
            userId: data[i].userID,
          });
          // eslint-disable-next-line no-loop-func
          userName = resp.data.name;
          newMessages.unshift({
            userID: data[i].userID,
            message: data[i].message,
            senderName: userName,
            filePath: data[i].filePath,
            fileTitle: data[i].fileTitle,
            messageFile: '',
            receiverName: `Quem recebeu${2}`,
            direction: '',
            created_at: timeStampToDate(data[i].created_at),
            isFile: data[i].isFile,
          });
        }
        setMessages(newMessages);
      });
    };
    const channel = pusher.subscribe(getProjectId().toString());
    channel.bind('update_messages_integrator', (data: any) => {
      getMessages();
    });

    getUser();
    getMessages();
    getProjectData();
    getCreditOptions();
    getEnables();
    getCostumer();

    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChosen(parseInt(e.target.value, 10));
    // eslint-disable-next-line no-unused-expressions
    projectStatus !== 'Inativo' ? setEnableButtonClick(false) : '';
  };
  const applyCurrencyMask = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoadingText('Enviando taxa escolhida...');
    setIsOpen(true);
    const chosen_analysisId = chosen;
    const data = {
      projectId: getProjectId(),
      chosen_analysisId,
    };
    if (chosen_analysisId) {
      const respo = await api.post('project/tax/put', {
        data,
      });
      if (respo.data !== 'CREDIT_OPTIONS_UPDATED') {
        toast.error(`Falha ao inserir a taxa aceita. ${respo.data}`);
        setIsOpen(false);
      } else {
        toast.success('Taxa escolhida com sucesso!');
        setIsOpen(false);
        setTimeout(() => {
          window.location.assign('/Debito');
        }, 1000);
      }
    } else {
      setIsOpen(false);
      toast.error('Escolha ao menos uma taxa!');
    }
  };
  const extensionFile = (fileName: string) => {
    let dots = [''];
    // eslint-disable-next-line no-return-assign
    return dots = fileName.split('.');
  };
  const getToday = () => {
    const date = new Date();
    const day = date.getDate().toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const hour = date.getHours();
    const min = date.getMinutes();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
    const year = date.getFullYear().toString();
    // check if date is after today
    return `${day}/${month}/${year} - ${hour}:${min}`;
  };

  const sendFileMessage = async (label: string, file: any, type: string) => {
    const body = new FormData();
    body.append('filefield', file, label);
    toast.success('Enviando arquivo, aguarde...');
    const response = await api.post('/upload/fileMessage', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        // @ts-ignore
        type,
      },
    });

    return response.data;
  };

  // const file = document.getElementById('upload_contrato');

  const handleAdicionarArquivo = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    // setMessageFile(fileList[0]);

    // @ts-ignore
    const type = extensionFile(e.target.files.item(0).name);
    const filePath2 = await sendFileMessage('messageFile', fileList[0], type[0]);

    const sendMessage = await api.post('/message/send', {
      data: {
        projectId: getProjectId(),
        message,
        userID,
        isFile: true,
        fileTitle: fileList[0].name,
        filePath: filePath2,
      },
    });

    if (sendMessage.data === 'CREATED_OK') {
      messages.push({
        message,
        userID: getUserId(),
        senderName: user,
        filePath: filePath2,
        messageFile: fileList[0],
        fileTitle: fileList[0].name,
        receiverName: 'Ele',
        direction: '',
        created_at: getToday(),
        isFile: true,
      });
      setMessageFile({ ...messageFile, file: fileList[0] });
    } else if (sendMessage.data === 'COULD_NOT_SEND_MESSAGE') {
      toast.warning('Não foi possível enviar o arquivo!');
    }
  };

  const checkFile = () => {
    if (messageFile !== null) {
      return true;
    }
    return false;
  };

  const submitMessage = async () => {
    // let filePath = '';
    // if (checkFile()) {
    //   filePath = await sendFile('messageFile', messageFile);
    // }

    if (message.length > 0) {
      const sendMessage = await api.post('/message/send', {
        data: {
          projectId: getProjectId(),
          message,
          userID,
          isFile: false,
          fileTitle: '',
          filePath: '',
        },
      });

      if (sendMessage.data === 'CREATED_OK') {
        messages.push({
          message,
          userID: getUserId(),
          senderName: user,
          filePath: '',
          messageFile: '',
          fileTitle: '',
          receiverName: 'Ele',
          direction: '',
          created_at: getToday(),
          isFile: false,
        });
        setMessage('');
        setMessageFile(null);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  async function loadPdfToCanvas(canvasRef: any, file: String, pageNumber : number) {
    const pdf = await pdfjs.getDocument(file).promise;

    // Prepare canvas using PDF page dimensions.
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');

    const page = await pdf.getPage(pageNumber);
    const viewport = await page.getViewport({ scale: 2.0 });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context.
    const renderContext = { canvasContext, viewport };
    page.render(renderContext);

    return pdf.numPages;
  }

  const handleNextPageClick = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber + 1);
    }
  };

  const handlePreviousPageClick = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber - 1);
    }
  };

  const handlePdfButtonClick = async (docUrl : string) => {
    ref?.current?.continuousStart(0, 500);

    const promise = getFile(docUrl);

    promise.then((resolved) => {
      setPageNumber(1);
      setDocumentUrl(resolved as string);
      const nPages = loadPdfToCanvas(canvasRef, resolved, pageNumber);

      nPages.then((num) => {
        setNumPages(num);
      });

      const element = document.getElementById('canvasPdf');
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      ref?.current?.complete();
    });
  };

  const handleAdicionarMensagem = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    setMessageFile(null);
  };

  const handleInputChangeRadioTypeFamily = (score: any) => {
    setRadioTypeFamilyValue(score);
  };

  const handleProposalChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setProposal(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadProposal = async () => {
    if (loadedFiles.proposal) {
      const file = await getFile(loadedFiles.proposal);
      window.open(file);
    }
  };

  const handleContaLuzChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setContaLuz(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadContaLuz = async () => {
    if (loadedFilesCostumer.contaLuz) {
      const file = await getFile(loadedFilesCostumer.contaLuz);
      window.open(file);
    }
  };
  const handleIdentidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIdentidade(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadIdentidade = async () => {
    if (loadedFilesCostumer.identidade) {
      const file = await getFile(loadedFilesCostumer.identidade);
      window.open(file);
    }
  };
  const handleComprovanteRendaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setComprovanteRenda(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadComprovanteRenda = async () => {
    if (loadedFilesCostumer.comprovanteRenda) {
      const file = await getFile(loadedFilesCostumer.comprovanteRenda);
      window.open(file);
    }
  };
  const handleIptuChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIptu(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadIptu = async () => {
    if (loadedFilesCostumer.iptu) {
      const file = await getFile(loadedFilesCostumer.iptu);
      window.open(file);
    }
  };
  const validateProposta = (m: string) => proposal;
  const validateContaLuz = (m: string) => contaLuz;
  const validateIdentidade = (m: string) => identidade;
  const validateIptu = (m: string) => iptu;
  const validateRendas = (m: string) => comprovanteRenda;
  const validateFormCostumer = () => {
    const messageDocs: any[] = [];
    if (contaLuz) {
      if (!isCostumerOwner && radioTypeFamilyValue === 0) {
        toast.info('Faltam Informações da conta de luz".');
        messageDocs.push(1);
      }
    }
    if (
      !validateProposta(proposal)
      && !validateRendas(comprovanteRenda)
      && !validateIdentidade(identidade)
      && !validateIptu(iptu)
      && !validateContaLuz(contaLuz)
    ) {
      toast.info('Nenhum documento para enviar!');
      messageDocs.push(1);
    }
    return messageDocs.length === 0;
  };
  const handleSubmitDocs = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateFormCostumer()) {
      setLoadingText('Salvando documentação...');
      setIsOpen(true);
      toast.info('Salvando informações do cliente! Por favor, aguarde');
      let fproposta = 'Sem documentos';
      let fcontaluz = 'Sem documentos';
      let fidentidade = 'Sem documentos';
      let fcomprovanteRenda = 'Sem documentos';
      let fiptu = 'Sem documentos';
      if (validateProposta(proposal)) {
        fproposta = await sendFile('proposal', proposal);
      }
      if (validateContaLuz(contaLuz)) {
        fcontaluz = await sendFile('contaLuz', contaLuz);
      }
      if (validateIdentidade(identidade)) {
        fidentidade = await sendFile('identidade', identidade);
      }
      if (validateRendas(comprovanteRenda)) {
        fcomprovanteRenda = await sendFile('comprovanteRenda', comprovanteRenda);
      }
      if (validateIptu(iptu)) {
        fiptu = await sendFile('iptu', iptu);
      }
      // ref!.current!.continuousStart(0, 200);
      const electricityBillOwnership = isCostumerOwner;
      const electricityBillFamiliarType = radioTypeFamilyValue;
      const projectId = getProjectId();
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          projectId,
          electricityBillFamiliarType,
          electricityBillOwnership,
          fproposta,
          fcontaluz,
          fidentidade,
          fcomprovanteRenda,
          fiptu,
        },
      };

      const res = await api.post('/project/sendCostumerDocs', request);

      switch (res.data) {
        case 'UNABLE_TO_UPDATE_DOC':
          toast.error('Aconteceu um problema!');
          break;
        case 'DOCS_UPDATED':
          // toast.success('Dados do
          // cliente cadastrados com sucesso. Aguardar a liberação
          // do painel de opções de crédito.');
          toast.success('Cadastro salvo!');
          ref?.current?.complete();
          window.location.reload();
          break;
        default:
          setIsOpen(false);
        // ref?.current?.complete();
      }
    } else {
      setIsOpen(false);
      // toast.warn('Alguns campos não estão preenchidos corretamente.');
    }
    // ref?.current?.complete();
  };
  const handleFocus = () => {

  };
  // @ts-ignore
  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <StepperProject activePage={3} projectId={getProjectId()} status={projectStatus} />
      <FormPage>
        <br />
        {costumer?.status !== 'Financiamento recusado'
          ? (
            <form>
              {
                projectStatus === 'Inativo'
                && (
                  <span style={{ color: 'red' }}>Este projeto está inativo!</span>
                )
              }
              <h1>Análise de Crédito</h1>
              {/* eslint-disable-next-line no-nested-ternary */}
              {(creditOptions.length > 0) ? creditOptions.map((x) => (
                <div className={style.listaCadastroItemClass}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: '35%' }}>
                          <span>Banco</span>
                          <br />
                          #
                          {x.banco}
                        </td>
                        <td style={{ width: '25%' }}>
                          <span>Número de Parcelas</span>
                          <br />
                          {x.parcelas}
                        </td>
                        <td className={style.cnpjCelula}>
                          <span>Valor da Parcela</span>
                          <br />
                          {applyCurrencyMask(x.valorParcela.toString())}
                        </td>
                        <td className={style.telefoneCelula} style={{ width: '15%' }}>
                          <span>Taxa</span>
                          <br />
                          {x.taxa}
                          %
                        </td>
                        {x.bancoDoc
                          ? (
                            <td className={style.taxaRadio}>
                              <button
                                className={style.detalheCadastroBtnAbrir}
                                type="button"
                                onClick={() => {
                                  handlePdfButtonClick(x.bancoDoc);
                                }}
                              >
                                <img
                                  className={style.detalheCadastroIcon}
                                  src={pdfIcon}
                                  alt="Abrir documento: Comprovante de Renda"
                                />
                              </button>
                            </td>
                          ) : ('PDF não enviado')}
                        {
                          projectStatus !== 'Inativo'
                          && (
                            <td className={style.taxaRadio}>
                              <span>Taxa Escolhida</span>
                              <br />
                              <input
                                disabled={enableRadioClick.valueOf()}
                                type="radio"
                                value={x.id}
                                checked={x.id === chosen}
                                onChange={handleRadioChange}
                              />
                            </td>
                          )
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))
                : (costumer?.status === 'Financiamento aprovado') ? 'Financiamento aprovado. Aguardando condições de crédito.'
                  : 'Projeto aguardando análise de crédito.' }
              <hr />
              <input type="submit" value="Enviar >" id="enviarBtn" disabled={projectStatus !== 'Aguardando escolha das condições'} onClick={handleSubmit} />
            </form>
          )
          : (
            <FormPage>
              <br />
              <form>
                <div className={style.listaCadastroItemClass}>
                  {/* <h1>Análise de Crédito</h1> */}
                  <h1>Financiamento recusado</h1>
                </div>
              </form>
            </FormPage>
          )}
        <br />
        <br />
        <form
          style={{ marginTop: '20px' }}
          className={
            documentUrl === ''
              ? style.detalheCotacaoSecondFrameDisabled
              : style.detalheCotacaoSecondFrame
          }
        >
          <div style={{
            background: '#eee',
            width: '100%',
            padding: '3px',
            color: '#666',
            fontSize: '11px',
          }}
          >
            <button type="button" onClick={handlePreviousPageClick}>Anter.</button>
            &nbsp;
            <button type="button" onClick={handleNextPageClick}>Próx.</button>
            &nbsp;&nbsp;
            {pageNumber}
            &nbsp;/&nbsp;
            {numPages}
          </div>
          <br />
          <canvas
            ref={canvasRef}
            id="canvasPdf"
            style={{ border: '1px solid #eee', width: '100%' }}
          />
        </form>
        <form>
          <h1>Documentação do Cliente</h1>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="upload_equipamento">
                    Enviar Proposta
                    <RequiredFieldMark />
                  </label>
                </td>
                <td>
                  <label>
                    {(projectStatus !== 'Projeto contratado')
                      ? ((!loadedFiles.proposal || loadedFiles.proposal === 'Sem documentos')
                          && (
                            <>
                              <input
                                id="upload_proposal"
                                name="upload_proposal"
                                onChange={handleProposalChange}
                                type="file"
                              />
                              <img
                                src={uploadIcon}
                                alt="upload proposal"
                                style={{ width: '30px' }}
                                className={style.uploadIcon}
                              />
                              <td>{proposal?.name}</td>
                            </>
                          )
                      ) : 'Não enviado'}
                    {
                    (loadedFiles.proposal !== 'Sem documentos' && loadedFiles.proposal)
                      && (<button type="button" onClick={handleDownloadProposal}>abrir arquivo</button>)
                  }
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="upload_contaluz">
                    Última Conta de Luz
                    <RequiredFieldMark />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      id="upload_contaluz"
                      name="upload_contaluz"
                      onChange={handleContaLuzChange}
                      type="file"
                    />
                    {
                    // eslint-disable-next-line no-nested-ternary
                    projectStatus === 'Projeto contratado' ? (
                      // eslint-disable-next-line no-nested-ternary
                      loadedFilesCostumer.contaLuz === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFilesCostumer.contaLuz ? ''
                          : (<button type="button" onClick={handleDownloadContaLuz}>abrir arquivo</button>)
                    ) : (
                      loadedFilesCostumer.contaLuz === 'Sem documentos' ? (
                        <img
                          src={uploadIcon}
                          alt="upload conta luz"
                          style={{ width: '15px' }}
                          className={style.uploadIcon}
                        />
                      ) : (
                        (<button type="button" onClick={handleDownloadContaLuz}>abrir arquivo</button>)
                      )
                    )
                  }
                  </label>
                </td>
                <td>{contaLuz?.name}</td>
              </tr>
              {contaLuz && (
              <tr>
                <tr>
                  <td className={style.electricity_bill_ownership}>
                    <label>
                      Conta de luz está no nome do cliente?
                    </label>
                  </td>
                </tr>
                <tr>
                  <td className={style.electricity_bill_ownership}>
                    <label>
                      <input
                        type="radio"
                        name="radioClientOwner"
                        checked={isCostumerOwner as boolean}
                        onChange={() => setIsCostumerOwner(true)}
                      />
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={!isCostumerOwner as boolean}
                        onChange={() => setIsCostumerOwner(false)}
                        name="radioClientOwner"
                      />
                      Não
                    </label>
                  </td>
                </tr>
              </tr>
              )}
              { !isCostumerOwner
                ? (
                  <tr>
                    <td className={style.electricity_bill_ownership}>
                      <label>
                        <input
                          value={1}
                          type="radio"
                          onChange={(e) => handleInputChangeRadioTypeFamily(1)}
                          name="radioTypeFamily"
                        />
                        Mãe
                      </label>
                      <label>
                        <input
                          value={2}
                          type="radio"
                        // checked={!isClientOwner as boolean}
                          onChange={(e) => handleInputChangeRadioTypeFamily(2)}
                          name="radioTypeFamily"
                        />
                        Pai
                      </label>
                      <label>
                        <input
                          value={3}
                          type="radio"
                        // checked={!isClientOwner as boolean}
                          onChange={(e) => handleInputChangeRadioTypeFamily(3)}
                          name="radioTypeFamily"
                        />
                        Esposo(a)
                      </label>
                      <label>
                        <input
                          value={4}
                          type="radio"
                        // checked={!isClientOwner as boolean}
                          onChange={(e) => handleInputChangeRadioTypeFamily(4)}
                          name="radioTypeFamily"
                        />
                        Filho(a)
                      </label>
                      <label>
                        <input
                          value={5}
                          type="radio"
                        // checked={!isClientOwner as boolean}
                          onChange={(e) => handleInputChangeRadioTypeFamily(5)}
                          name="radioTypeFamily"
                        />
                        Irmão/Irmã
                      </label>
                    </td>
                  </tr>
                )
                : ''}
              <tr>
                <td>
                  <label htmlFor="upload_identidade">
                    Documento de Identificação
                    <RequiredFieldMark />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      id="upload_identidade"
                      name="upload_identidade"
                      onChange={handleIdentidadeChange}
                      type="file"
                    />
                    {
                    // eslint-disable-next-line no-nested-ternary
                    projectStatus === 'Projeto contratado' ? (
                      // eslint-disable-next-line no-nested-ternary
                      loadedFilesCostumer.identidade === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFilesCostumer.identidade ? ''
                          : (<button type="button" onClick={handleDownloadIdentidade}>abrir arquivo</button>)
                    ) : (
                      loadedFilesCostumer.identidade === 'Sem documentos' ? (
                        <img
                          src={uploadIcon}
                          alt="upload documento identificação"
                          style={{ width: '15px' }}
                          className={style.uploadIcon}
                        />
                      ) : (
                        (<button type="button" onClick={handleDownloadIdentidade}>abrir arquivo</button>)
                      )
                    )
                  }
                  </label>
                </td>
                <td>{identidade?.name}</td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="upload_comprovanteRenda">
                    Comprovante de Renda
                    <RequiredFieldMark />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      id="upload_comprovanteRenda"
                      name="upload_comprovanteRenda"
                      onChange={handleComprovanteRendaChange}
                      type="file"
                    />
                    {
                    // eslint-disable-next-line no-nested-ternary
                    projectStatus === 'Projeto contratado' ? (
                      // eslint-disable-next-line no-nested-ternary
                      loadedFilesCostumer.comprovanteRenda === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFilesCostumer.comprovanteRenda ? ''
                          : (<button type="button" onClick={handleDownloadComprovanteRenda}>abrir arquivo</button>)
                    ) : (
                      loadedFilesCostumer.comprovanteRenda === 'Sem documentos' ? (
                        <img
                          src={uploadIcon}
                          alt="upload comprovante renda"
                          style={{ width: '15px' }}
                          className={style.uploadIcon}
                        />
                      ) : (
                        (<button type="button" onClick={handleDownloadComprovanteRenda}>abrir arquivo</button>)
                      )
                    )
                  }
                  </label>
                </td>
                <td>{comprovanteRenda?.name}</td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="upload_iptu">
                    IPTU (em nome do cliente)
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      id="upload_iptu"
                      name="upload_iptu"
                      onChange={handleIptuChange}
                      type="file"
                    />
                    {
                    // eslint-disable-next-line no-nested-ternary
                    projectStatus === 'Projeto contratado' ? (
                      // eslint-disable-next-line no-nested-ternary
                      loadedFilesCostumer.iptu === 'Sem documentos'
                        ? 'Não enviado'
                        : !loadedFilesCostumer.iptu ? ''
                          : (<button type="button" onClick={handleDownloadIptu}>abrir arquivo</button>)
                    ) : (
                      loadedFilesCostumer.iptu === 'Sem documentos' ? (
                        <img
                          src={uploadIcon}
                          alt="upload comprovante iptu"
                          style={{ width: '15px' }}
                          className={style.uploadIcon}
                        />
                      ) : (
                        (<button type="button" onClick={handleDownloadIptu}>abrir arquivo</button>)
                      )
                    )
                  }
                  </label>
                </td>
                <td>{iptu?.name}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <input
            type="submit"
            id="submit_btn"
            value="Enviar documentação"
            disabled={projectStatus === 'Projeto contratado' || projectStatus === 'Inativo'}
            onClick={handleSubmitDocs}
          />
        </form>
        <br />
        <br />
        <div className={style.analiseCreditoMensagemContainer}>
          <h1>
            Mensagens
          </h1>
          <div className={style.analiseCreditoListaMensagem}>
            {(messages.length > 0) ? messages.map((m) => (
              // @ts-ignore
              <Post dataProp={
                {
                  userID: m.userID,
                  senderName: m.senderName,
                  filePath: m.filePath,
                  fileTitle: m.fileTitle,
                  isFile: m.isFile,
                  message: m.message,
                  direction: m.direction,
                  receiverName: m.receiverName,
                  created_at: m.created_at,
                }
              }
              />
            )) : '' }
          </div>

          <div className={style.analiseCreditoInputMensagem}>
            <input
              type="textarea"
              id="messageTextArea"
              placeholder="Nova mensagem"
              value={message}
              className={style.textareaDefaultStyle}
              onChange={handleAdicionarMensagem}
              onFocus={handleFocus}
              onKeyDown={(e) => { if (e.key === 'Enter') submitMessage(); }}
            />
            <button
              type="button"
              className={style.analiseCreditoMensagemButton}
              onClick={submitMessage}
            >
              Enviar mensagem
            </button>
            <label>
              <input
                id="upload_contrato"
                name="upload_contrato"
                type="file"
                onInput={handleAdicionarArquivo}
              />
              <img
                src={uploadIcon}
                alt="upload certidão de registro profissional"
                className={style.uploadIcon}
              />
            </label>
          </div>
        </div>
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <ToastContainer />
    </div>
  );
}
