import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { toast, ToastContainer } from 'react-toastify';
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import style from './reenviodocumentosintegradora.module.scss';
import { api } from '../../services/api';
import { Header } from '../../components/Header';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';
import { Baloon } from '../../components/Baloon';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { LoadingIcon } from '../../components/LoadingIcon';
import uploadIcon from '../../assets/images/upload.png';
import { DialogComponent } from '../../components/DialogComponent';
import { Footer } from '../../components/Footer';

interface IntegratorEval {
  id: number;
  datahora: Date;

  homologaContrato: Boolean;
  homologaCrea: Boolean;
  homologaBalancete: Boolean;
  homologaCrp: Boolean;
  homologaCat: Boolean;

  userHomologadorId: number;

  justificativa: String;
}
interface IntegratorDetalheData {
  id: number;
  name: String;
  cnpj: String;
  phoneNumber: String;
  potencyW: String;
  numWorkers: number;
  dataFundacao: String;
  status: String;
  user: {
    email: String;
  };
  address: {
    street1: String;
    number: number;
    street2: String;
    zip: String;
    city: {
      name: String;
      state: {
        name: String;
      };
    };
  };
}
export function ReenvioDocumentosIntegradora() {
  const ref = useRef<LoadingBarRef>(null);

  const { 'nextauth.tag': tag } = parseCookies();
  const getUserId = () => parseInt(tag, 10);
  const [contrato, setContrato] = useState<any>();

  const [homoContrato, setHomoContrato] = useState<Boolean>(false);
  const [homoCrea, setHomoCrea] = useState<Boolean>(false);
  const [homoBalancete, setHomoBalancete] = useState<Boolean>(false);
  const [homoCrp, setHomoCrp] = useState<Boolean>(false);
  const [homoCat, setHomoCat] = useState<Boolean>(false);
  const [homojustificativa, setHomoJustificativa] = useState<any>('');
  const [crea, setCrea] = useState<any>();
  const [balanco, setBalanco] = useState<any>();
  const [crp, setCrp] = useState<any>();
  const [cat, setCat] = useState<any>();
  const [integrator, setIntegrator] = useState<IntegratorDetalheData | null>(
    null,
  );
  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };
  const instrucoes = 'Envie novamente os documentos que não forma homologados conforme a justificativa informada abaixo.';

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const getIntegrator = async () => {
      setLoadingText('Verificando status!...');
      setIsOpen(true);
      const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/user/get', {
        id: getUserId(),
      });
      resp.then((resolved) => {
        const item: IntegratorDetalheData = resolved.data;
        setIntegrator(item);
        setIsOpen(false);
        return item;
      });
    };
    const getIntegratorEvaluation = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/eval/get', {
        id: integrator?.id,
      });

      resp.then((resolved) => {
        const item: IntegratorEval = resolved.data;

        const {
          homologaContrato,
          homologaCrea,
          homologaBalancete,
          homologaCrp,
          homologaCat,
          justificativa,
        } = item;

        setHomoContrato(homologaContrato);
        setHomoCrea(homologaCrea);
        setHomoBalancete(homologaBalancete);
        setHomoCrp(homologaCrp);
        setHomoCat(homologaCat);
        setHomoJustificativa(justificativa);
        setAttribute('f_justify', 'value', justificativa);
      });
    };

    getIntegrator();
    getIntegratorEvaluation();
  });
  const refreshPage = (interval: number) => setInterval(() => window.location.reload(), interval);
  const [errorMessageInput, setErrorMessageInput] = useState<string[]>([]);
  const [errorMessageUpload, setErrorMessageUpload] = useState<string[]>([]);

  const TestFileType = (fileName: string, fileTypes : [string]) => {
    if (!fileName) return;

    const dots = fileName.split('.');
    // get the part AFTER the LAST period.
    const fileType = `.${dots[dots.length - 1]}`;

    // eslint-disable-next-line consistent-return
    if (fileTypes.join('.').indexOf(fileType) !== -1) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };
  const handleCatChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCat(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleBalancoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setBalanco(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleCreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCrea(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleCrpChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setCrp(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };

  const handleContratoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setContrato(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
    // state.contrato_64 = URL.createObjectURL(fileList[0]);
    // state.contrato = fileList.item(0);
  };
  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const response = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const validateContrato = (m: any) => m;
  const validateCrea = (m: any) => m;
  const validateBalanco = (m: any) => m;
  const validateCrp = (m: any) => m;
  const validateCat = (m: any) => m;
  const validateForm = () => {
    const messagesInput: any[] = [];
    const messagesUpload: any[] = [];

    if (!homoContrato && !validateContrato(contrato)) {
      messagesUpload.push('Revise o upload do contrato social');
    }
    if (!homoCrea && !validateCrea(crea)) {
      messagesUpload.push('Revise o upload do CREA');
    }
    if (!homoBalancete && !validateBalanco(balanco)) {
      messagesUpload.push('Revise o upload do balanço ou balancete');
    }
    if (!homoCrp && !validateCrp(cat)) {
      messagesUpload.push('Revise o upload do CAT da empresa');
    }
    if (!homoCat && !validateCat(crp)) {
      messagesUpload.push('Revise o upload da certidão de registro profissional');
    }
    setErrorMessageInput(messagesInput);
    setErrorMessageUpload(messagesUpload);
    return messagesUpload.length === 0;
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoadingText('Enviando cadastro...');
      setIsOpen(true);
      const urlv = window.location.href.split('/');
      const urlc = `${urlv[0]}//${urlv[2]}`;
      let fcontrato = contrato;
      let fcat = cat;
      let fcrea = crea;
      let fcrp = crp;
      let fbalanco = balanco;
      toast.info('Enviando Cadastro. Por favor, aguarde um instante.');
      if (contrato) {
        fcontrato = await sendFile('contrato', contrato);
      }
      if (cat) {
        fcat = await sendFile('cat', cat);
      }
      if (crea) {
        fcrea = await sendFile('crea', crea);
      }
      if (crp) {
        fcrp = await sendFile('crp', crp);
      }
      if (balanco) {
        fbalanco = await sendFile('balanco', balanco);
      }
      const integratorEmail = integrator?.user.email;
      const integratorId = integrator?.id;

      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          // razaoSocial,
          // cnpj,
          // telefone,
          // rua,
          // numero,
          // complemento,
          // cep,
          // cidade,
          // potenciaInstalada,
          // quantidadeFuncionarios,
          // dataFundacao,
          integratorEmail,
          integratorId,
          returnUrl: urlc,
          fcontrato,
          fcrea,
          fcat,
          fcrp,
          fbalanco,
        },
      };
      const response = await api.post('integrator/register/put', request);

      switch (response.data) {
        case 'DOCS_SEND_OK':
          setIsOpen(false);
          toast.success('Documentos enviados.');
          refreshPage(100);
          break;
        case 'UNABLE_TO_FIND_INTEGRATOR':
          setIsOpen(false);
          toast.error('Email já cadastrado na base de dados.');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
      }
    } else {
      setIsOpen(false);
      toast.warn('Há um problema com os arquivos.');
    }
  };

  // @ts-ignore
  return (
    <div>
      <LoadingBar color="#f11946" ref={ref} />
      <FormPage>
        <br />
        {integrator?.status === 'Recusado'
          ? (
            <form onSubmit={handleSubmit} id="formIntegrator" encType="multipart/form-data">
              <h1>Documentos da Integradora</h1>
              <Baloon mode="clean">{instrucoes}</Baloon>
              {errorMessageInput.length > 0 && (
              <Baloon mode="warning">
                <ul>
                  {errorMessageInput.map((m) => (
                    <li key={m}>{m}</li>
                  ))}
                </ul>
              </Baloon>
              )}
              <h1>Documentação</h1>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="upload_contrato">
                        Contrato Social
                        <RequiredFieldMark />
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          // @ts-ignore
                          disabled={homoContrato}
                          id="upload_contrato"
                          name="upload_contrato"
                          onChange={handleContratoChange}
                          type="file"
                        />
                        <img
                          src={uploadIcon}
                          alt="upload certidão de registro profissional"
                          className={style.uploadIcon}
                        />
                      </label>
                    </td>
                    <td>{contrato?.name}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name="radioContrato"
                          checked={homoContrato as boolean}
                          // onChange={() => setHomoContrato(true)}
                        />
                        Homologado
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={!homoContrato as boolean}
                          // onChange={() => setHomoContrato(false)}
                          name="radioContrato"
                        />
                        Não homologado
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="upload_certidao">
                        Certidão de registro no CREA
                        <RequiredFieldMark />
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          // @ts-ignore
                          disabled={homoCrea}
                          id="upload_crea"
                          name="upload_crea"
                          onChange={handleCreaChange}
                          type="file"
                        />
                        <img
                          src={uploadIcon}
                          alt="upload CREA"
                          className={style.uploadIcon}
                        />
                      </label>
                    </td>
                    <td>{crea?.name}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name="radioCrea"
                          checked={homoCrea as boolean}
                          // onChange={() => setHomoContrato(true)}
                        />
                        Homologado
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={!homoCrea as boolean}
                          // onChange={() => setHomoContrato(false)}
                          name="radioCrea"
                        />
                        Não homologado
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Balanço ou balancete dos últimos 12 meses
                        <RequiredFieldMark />
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          // @ts-ignore
                          disabled={homoBalancete}
                          id="upload_balanco"
                          name="upload_balanco"
                          onChange={handleBalancoChange}
                          type="file"
                        />
                        <img
                          src={uploadIcon}
                          alt="upload balanço"
                          className={style.uploadIcon}
                        />
                      </label>
                    </td>
                    <td>{balanco?.name}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name="radiobalancete"
                          checked={homoBalancete as boolean}
                          // onChange={() => setHomoContrato(true)}
                        />
                        Homologado
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={!homoBalancete as boolean}
                          // onChange={() => setHomoContrato(false)}
                          name="radiobalancete"
                        />
                        Não homologado
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        CAT da Empresa
                        <RequiredFieldMark />
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          // @ts-ignore
                          disabled={homoCat}
                          id="upload_cat"
                          name="upload_cat"
                          onChange={handleCatChange}
                          type="file"
                        />
                        <img
                          src={uploadIcon}
                          alt="upload CAT"
                          className={style.uploadIcon}
                        />
                      </label>
                    </td>
                    <td>{cat?.name}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name="radiocat"
                          checked={homoCat as boolean}
                          // onChange={() => setHomoContrato(true)}
                        />
                        Homologado
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={!homoCat as boolean}
                          // onChange={() => setHomoContrato(false)}
                          name="radiocat"
                        />
                        Não homologado
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>
                        Certidão de registro profissional
                        <RequiredFieldMark />
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          // @ts-ignore
                          disabled={homoCrp}
                          id="upload_crp"
                          name="upload_crp"
                          onChange={handleCrpChange}
                          type="file"
                        />
                        <img
                          src={uploadIcon}
                          alt="upload certidão de registro profissional"
                          className={style.uploadIcon}
                        />
                      </label>
                    </td>
                    <td>{crp?.name}</td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name="radiocrp"
                          checked={homoCrp as boolean}
                          // onChange={() => setHomoContrato(true)}
                        />
                        Homologado
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={!homoCrp as boolean}
                          // onChange={() => setHomoContrato(false)}
                          name="radiocrp"
                        />
                        Não homologado
                      </label>
                    </td>
                  </tr>
                  <label>
                    Justificativa
                    <RequiredFieldMark />
                  </label>
                  <label>
                    <textarea
                      disabled
                      value={homojustificativa}
                      className={style.detalheCadastroJustificativa}
                    />
                  </label>
                </tbody>
              </table>
              {errorMessageUpload.length > 0 && (
              <Baloon mode="warning">
                <ul>
                  {errorMessageUpload.map((m) => (
                    <li key={m}>{m}</li>
                  ))}
                </ul>
              </Baloon>
              )}
              <hr />
              <input type="submit" value="Enviar >" />
            </form>
          )
          : (
            <form onSubmit={handleSubmit} id="formIntegrator" encType="multipart/form-data">
              <h1>Documentos da Integradora</h1>
              <span>Documentos em analise!</span>
            </form>
          )}
        <br />
      </FormPage>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
