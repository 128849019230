import React, {
  ChangeEvent,
  useState, SyntheticEvent, useEffect, useRef,
} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import { LoadingBarRef } from 'react-top-loading-bar';
import style from './contrato.module.scss';

import { FormPage } from '../../components/FormPage';

import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { StepperProject } from '../../components/StepperProject';
import uploadIcon from '../../assets/images/upload.png';
import { getFile } from '../../lib/download';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';

interface Project {
  nome: string;
  status: string;
  proposal: string;
  invoice: string;
  receipt: string;
}

export function Contrato() {
  const [proposal, setProposal] = useState<any>();
  const [invoice, setInvoice] = useState<any>();
  const [receipt, setReceipt] = useState<any>();
  const [project, setProject] = useState<Project>();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [equipamento, setEquipamento] = useState<any>();
  const [contaLuz, setContaLuz] = useState<any>();
  const [identidade, setIdentidade] = useState<any>();
  const [iptu, setIptu] = useState<any>();
  const [comprovanteRenda, setComprovanteRenda] = useState<any>();
  const [invoiceSelected, setInvoiceSelected] = useState(false);
  const [invoiceEquipamentSelected, setInvoiceEquipamentSelected] = useState(false);

  const ref = useRef<LoadingBarRef>(null);
  const [isCostumerOwner, setIsCostumerOwner] = useState<Boolean>(true);
  const [radioTypeFamilyValue, setRadioTypeFamilyValue] = useState(0);
  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const [loadedFiles, setLoadedFiles] = useState({
    proposal: undefined,
    invoice: undefined,
    receipt: undefined,
  });
  const [loadedFilesCostumer, setLoadedFilesCostumer] = useState({
    equipamento: null,
    contaLuz: null,
    identidade: null,
    iptu: null,
    comprovanteRenda: null,
  });
  const validateForm = () => {
    const messages: any[] = [];
    // if (!proposal && loadedFiles.proposal === null) {
    //   toast.info('Não foi realizado o upload da "Proposta".');
    //   messages.push(1);
    // }
    if (!invoice) {
      toast.info('Não foi realizado o upload da "Nota Fiscal".');
      messages.push(1);
    }

    return messages.length === 0;
  };
  const validateReceipt = () => {
    const messages: any[] = [];
    if (!receipt) {
      toast.info('Não foi realizado o upload da "Comprovante de Depósito".');
      messages.push(1);
    }
    return messages.length === 0;
  };

  const validateContaLuz = (m: string) => contaLuz;
  const validateEquipamento = (m: string) => equipamento;
  const validateIdentidade = (m: string) => identidade;
  const validateIptu = (m: string) => iptu;
  const validateRendas = (m: string) => comprovanteRenda;

  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);

    const resp = await api.post('upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp.data;
  };
  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };
  const disableFields = () => {
    setAttribute('upload_equipamento', 'disabled', 'disabled');
  };
  const validateFormCostumer = () => {
    const messages: any[] = [];
    if (
      !validateEquipamento(equipamento)
    ) {
      toast.info('Não foi realizado o upload da "Nota Fiscal do Equipamento"');
      messages.push(1);
    }
    return messages.length === 0;
  };
  useEffect(() => {
    const getProjectData = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'project/single/get',
        { projectId: getProjectId() },
      );

      response.then((resolved) => {
        const { data } = resolved;
        setProject(data);
        setLoadedFiles({
          proposal: data.proposal || 'Sem documentos',
          invoice: data.invoice || 'Sem documentos',
          receipt: data.receipt || 'Sem documentos',
        });
      });
    };
    const getCostumer = async () => {
      setLoadingText('Carregando dados...');
      setIsOpen(true);
      const req = { projectId: getProjectId() };
      const costumer = await api.post('/project/costumer/get', req);
      const { data } = costumer;
      if (data !== 'COULD_NOT_FIND_COSTUMER') {
        setLoadedFilesCostumer({
          contaLuz: data.contaLuzRecente,
          identidade: data.documentoIdentificacao,
          equipamento: data.notaFiscalEquipamentos,
          iptu: data.iptu,
          comprovanteRenda: data.comprovanteRenda,
        });
      } else {
        const res: Promise<AxiosResponse<any, any>> = api.post(
          'project/single/get',
          { projectId: getProjectId() },
        );

        res.then((resolved) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const { data } = resolved;

          if (data.status === 'Inativo') {
            disableFields();
          }
        });
      }
      setIsOpen(false);
      return data;
    };
    getProjectData();
    getCostumer();

    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoadingText('Enviando documentos...');
      setIsOpen(true);
      const finvoice = await sendFile('invoice', invoice);
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          projectId: getProjectId(),
          invoice: finvoice,
        },
      };

      const response = await api.post('project/sendInvoiceDoc', request);

      switch (response.data) {
        case 'UNABLE_TO_FIND_PROJECT':
          setIsOpen(false);
          toast.warn(
            'Houve um problema e o projeto não foi localizado, tente novamente mais tarde ou entre em contato com a Soltech.',
          );
          break;
        case 'PROJECT_DOCS_SENT':
          setIsOpen(false);
          toast.success('Documentos enviados com sucesso.');
          window.location.assign('/Debito');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao enviar os documentos.${response.data}`);
          break;
      }
    }
  };

  const handleSubmitReceipt = async () => {
    if (validateReceipt()) {
      setLoadingText('Enviando comprovante...');
      setIsOpen(true);
      const freceipt = await sendFile('receipt', receipt);
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          projectId: getProjectId(),
          receipt: freceipt,
        },
      };

      const response = await api.post('project/sendReceiptDoc', request);

      switch (response.data) {
        case 'UNABLE_TO_FIND_PROJECT':
          setIsOpen(false);
          toast.warn(
            'Houve um problema e o projeto não foi localizado, tente novamente mais tarde ou entre em contato com a Soltech.',
          );
          break;
        case 'PROJECT_DOCS_SENT':
          setIsOpen(false);
          toast.success('Comprovante enviado com sucesso.');
          window.location.assign('/Contrato');
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao enviar o comprovante.${response.data}`);
          break;
      }
    }
  };

  const handleSubmitCostumer = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateFormCostumer()) {
      setLoadingText('Salvando documentação...');
      setIsOpen(true);
      toast.info('Salvando informações do cliente! Por favor, aguarde');
      let fequipamento = 'Sem documentos';
      if (validateEquipamento(equipamento)) {
        fequipamento = await sendFile('equipamento', equipamento);
      }
      const projectId = getProjectId();
      const request = {
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          projectId,
          fequipamento,
        },
      };

      const res = await api.post('/project/sendCostumerEquipmentDoc', request);

      switch (res.data) {
        case 'UNABLE_TO_UPDATE_DOC':
          toast.error('Aconteceu um problema!');
          break;
        case 'DOC_UPDATED':
          // toast.success('Dados do
          // cliente cadastrados com sucesso. Aguardar a liberação
          // do painel de opções de crédito.');
          toast.success('Nota Fiscal Enviada!');
          ref?.current?.complete();
          window.location.reload();
          break;
        default:
          setIsOpen(false);
        // ref?.current?.complete();
      }
    } else {
      setIsOpen(false);
      // toast.warn('Alguns campos não estão preenchidos corretamente.');
    }

    // ref?.current?.complete();
  };

  const handleInputChangeRadioTypeFamily = (score: any) => {
    setRadioTypeFamilyValue(score);
  };
  const TestFileType = (fileName: string, fileTypes : [string]) => {
    if (!fileName) return;

    const dots = fileName.split('.');
    // get the part AFTER the LAST period.
    const fileType = `.${dots[dots.length - 1]}`;

    // eslint-disable-next-line consistent-return
    if (fileTypes.join('.').indexOf(fileType) !== -1) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };
  const handleProposalChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setProposal(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadProposal = async () => {
    if (loadedFiles.proposal) {
      const file = await getFile(loadedFiles.proposal);
      window.open(file);
    }
  };
  const handleInvoiceChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setInvoice(fileList[0]);
      setInvoiceSelected(true);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadInvoice = async () => {
    if (loadedFiles.invoice) {
      const file = await getFile(loadedFiles.invoice);
      window.open(file);
    }
  };
  const handleReceiptChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setReceipt(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadReceipt = async () => {
    if (loadedFiles.receipt) {
      const file = await getFile(loadedFiles.receipt);
      window.open(file);
    }
  };
  const handleEquipamentoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setEquipamento(fileList[0]);
      setInvoiceEquipamentSelected(true);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadEquipamento = async () => {
    if (loadedFilesCostumer.equipamento) {
      const file = await getFile(loadedFilesCostumer.equipamento);
      window.open(file);
    }
  };
  const handleComprovanteRendaChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setComprovanteRenda(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadComprovanteRenda = async () => {
    if (loadedFilesCostumer.comprovanteRenda) {
      const file = await getFile(loadedFilesCostumer.comprovanteRenda);
      window.open(file);
    }
  };
  const handleIptuChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIptu(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadIptu = async () => {
    if (loadedFilesCostumer.iptu) {
      const file = await getFile(loadedFilesCostumer.iptu);
      window.open(file);
    }
  };
  const handleContaLuzChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setContaLuz(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadContaLuz = async () => {
    if (loadedFilesCostumer.contaLuz) {
      const file = await getFile(loadedFilesCostumer.contaLuz);
      window.open(file);
    }
  };
  const handleIdentidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setIdentidade(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça apenas o upload de arquivos com a extensão .pdf!');
    }
  };
  const handleDownloadIdentidade = async () => {
    if (loadedFilesCostumer.identidade) {
      const file = await getFile(loadedFilesCostumer.identidade);
      window.open(file);
    }
  };

  // @ts-ignore
  return (
    <div>
      <StepperProject activePage={4} projectId={getProjectId()} />
      <FormPage>
        <br />
        <form className={style.contratoSplitter}>
          <span className={style.contratoSplitterSection}>
            <h1>Contratação do Projeto</h1>
            <div>
              <table>
                <tbody>
                  <tr style={{ border: 'solid 1px black' }}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <label htmlFor="upload_equipamento">Nota Fiscal</label>
                      <RequiredFieldMark />
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {project?.status === 'Projeto contratado' ? (
                        (loadedFiles.invoice === 'Sem documentos'
                          || loadedFiles.invoice === ''
                          || loadedFiles.invoice === null) ? (
                            <td>
                              <label>
                                <label htmlFor="upload_equipamento">{/* Abrir arquivo enviado anteriormente */}</label>
                                <button type="button" onClick={handleDownloadInvoice}>
                                  Abrir arquivo
                                </button>
                              </label>
                            </td>
                          ) : (
                            <label>Não enviado</label>
                          )
                      ) : (
                        (loadedFiles.invoice === null || loadedFiles.invoice === 'Sem documentos') ? (
                          <div>
                            <label>
                              <input
                                id="upload_invoice"
                                name="upload_invoice"
                                onChange={handleInvoiceChange}
                                type="file"
                              />
                              <img
                                src={uploadIcon}
                                alt="upload invoice"
                                style={{ width: '30px' }}
                                className={style.uploadIcon}
                              />
                            </label>
                            <label>{invoice?.name}</label>
                          </div>
                        ) : (
                          <button type="button" onClick={handleDownloadEquipamento}>
                            Abrir arquivo
                          </button>
                        )
                      )}
                    </td>
                    {(project?.status === 'Projeto contratado' || project?.status === 'Inativo')
                      ? (
                        ''
                      ) : (
                    // <div style={{ display: 'flex', flexDirection: 'column' }}>
                        invoiceSelected && (
                        <button style={{ display: 'block' }} type="button" onClick={handleSubmit}>
                          Enviar nota fiscal
                        </button>
                        )
                    // </div>
                      )}
                  </tr>
                  <br />
                  <tr style={{ border: 'solid 1px black' }}>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <label>
                        Nota Fiscal do Equipamento
                      </label>
                      <RequiredFieldMark />
                      <div>
                        <label>
                          {project?.status !== 'Projeto contratado' && (
                          <input
                            id="upload_equipamento"
                            name="upload_equipamento"
                            onChange={handleEquipamentoChange}
                            type="file"
                          />
                          )}
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {project?.status === 'Projeto contratado' ? (
                            loadedFilesCostumer.equipamento === 'Sem documentos'
                          || loadedFilesCostumer.equipamento === ''
                          || loadedFilesCostumer.equipamento === null
                              ? <span>Não enviado</span>
                              : (
                                <button type="button" onClick={handleDownloadEquipamento}>
                                  Abrir arquivo
                                </button>
                              )
                          ) : (
                            (loadedFilesCostumer.equipamento === null || loadedFilesCostumer.equipamento === 'Sem documentos') ? (
                              <img
                                src={uploadIcon}
                                alt="upload nota equipamento"
                                style={{ width: '30px' }}
                                className={style.uploadIcon}
                              />
                            ) : (
                              <button type="button" onClick={handleDownloadEquipamento}>
                                Abrir arquivo
                              </button>
                            )
                          )}
                        </label>
                        <label>{equipamento?.name}</label>
                      </div>
                    </td>
                    {(project?.status === 'Projeto contratado' || project?.status === 'Inativo')
                      ? (
                        ''
                      ) : (
                        // <div style={{ display: 'flex', flexDirection: 'column' }}>
                        invoiceEquipamentSelected && (
                        <button
                          style={{ display: 'block' }}
                          type="button"
                          id="submit_btn"
                          value="Enviar documentação"
                          disabled={project?.status === 'Projeto contratado' || project?.status === 'Inativo'}
                          onClick={handleSubmitCostumer}
                        >
                          Enviar nota fiscal do equipamento
                        </button>
                        )
                      )}
                  </tr>
                  <br />
                  <tr style={{ border: 'solid 1px black' }}>
                    {
                    // eslint-disable-next-line no-nested-ternary
                    (loadedFiles.receipt && loadedFiles.receipt !== 'Sem documentos')
                      ? (
                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <label htmlFor="upload_equipamento">
                            Comprovante de Depósito
                          </label>
                          <label>
                            <label htmlFor="upload_receipt">
                              {/* Abrir arquivo enviado anteriormente */}
                            </label>
                            <button type="button" onClick={handleDownloadReceipt}>Abrir Arquivo</button>
                          </label>
                        </td>
                      ) : (
                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <label htmlFor="upload_equipamento">
                            Comprovante de Depósito
                          </label>
                          <label>
                            <label />
                            Não enviado
                          </label>
                        </td>
                      )
                  }
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </form>
        <br />
        <br />
      </FormPage>
      <ToastContainer />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />

    </div>
  );
}
