import ReactNode, { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar, Box, ListItemIcon, Menu, MenuItem, Tooltip, Typography, IconButton,
} from '@mui/material';
import { Logout, Badge } from '@mui/icons-material';
import { parseCookies } from 'nookies';
import { toast, ToastContainer } from 'react-toastify';
import { signOut } from '../../hooks/useAuth';
import { getFile } from '../../lib/download';
import { api } from '../../services/api';
import NotificationsButton from '../NotificationsButton';
import NotificationsMessagesButton from '../NotificationsMessagesButton';
import IntegratorNotificationsButton from '../IntegratorNotificationsButton';

interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  phone: string,
  // integradora: Integrator
  userAvatar: string,
}

interface Props {
  // @ts-ignore
  children?: ReactNode;
  isMobileMenu?: boolean;
}

const BotaoUsuario = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = useState<User>();
  const [userAvatar, setUserAvatr] = useState('');
  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const getUserId = () => {
    const { 'nextauth.tag': tag } = parseCookies();
    return parseInt(tag, 10);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { children, isMobileMenu } = props;
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      const userId = getUserId();
      const resp = await api.post('/user/get', { userId });
      const { data } = resp;
      if (data !== 'COULD_NOT_FIND_USER') {
        setUser(data);
        if (data.userAvatar) {
          setUserAvatr(await getFile(data.userAvatar));
        }
      }
    };
    getUser();
  }, []);
  const clickHandle = () => {
    signOut();
    history.push('/Login');
  };
  const clickProfile = () => {
    history.push('/UserProfile');
  };

  const { 'nextauth.tag': userId } = parseCookies();
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: { xs: 'column-reverse', lg: 'row' },
          gap: '1rem',
        }}
      >
        {
          cookieRoles === 'Integradora' || cookieRoles === 'Vendedor'
            ? '' : <NotificationsMessagesButton />
        }
        {
          cookieRoles === 'Integradora' || cookieRoles === 'Vendedor'
            ? '' : <NotificationsButton />
        }
        {
          cookieRoles === 'Integradora'
            && <IntegratorNotificationsButton />
        }
        <Typography>
          {`Olá, ${user?.name}`}
        </Typography>
        <Tooltip title="Menu do usuário">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar src={userAvatar} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            // @ts-ignore
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem onClick={clickProfile}>
          <ListItemIcon>
            <Badge fontSize="small" />
          </ListItemIcon>
          {' '}
          {cookieRoles !== 'Integradora' ? 'Meus dados' : 'Dados da Integradora'}
        </MenuItem>
        {/* <MenuItem> */}
        {/*   <Avatar /> */}
        {/*   {' '} */}
        {/*   My account */}
        {/* </MenuItem> */}
        {/* <Divider /> */}
        {/* <MenuItem> */}
        {/*   <ListItemIcon> */}
        {/*     <PersonAdd fontSize="small" /> */}
        {/*   </ListItemIcon> */}
        {/*   Add another account */}
        {/* </MenuItem> */}
        {/* <MenuItem> */}
        {/*   <ListItemIcon> */}
        {/*     <Settings fontSize="small" /> */}
        {/*   </ListItemIcon> */}
        {/*   Settings */}
        {/* </MenuItem> */}
        <MenuItem onClick={clickHandle}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </>
    // <div className={style.botaousuario}>
    //   {children}
    //   <img className={style.imgUser} src={userImage} alt="Foto Usuário" />
    //   {/* <img className={style.imgDrop} src={dropImage} alt="Foto Usuário" /> */}
    //   <br />
    //   <button type="button" onClick={clickHandle}>
    //     Sair
    //   </button>
    // </div>
  );
};

export { BotaoUsuario };
